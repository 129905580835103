.taskbar-container {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 4;
}

.taskbar-container .credits {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-self: flex-end;
    align-items: flex-end;
    bottom: 50px;
    margin-right: 10px;
    gap: 10px;
}

.taskbar-container .start-content {
    background-color: #C0C0C0;
    display: flex;
    flex-direction: row;
    width: 220px;
    gap: 15px;
    border-top: 2px solid #DFDFDF;
    border-left: 2px solid #DFDFDF;
    border-right: 2px solid #808080;
    border-bottom: 2px solid #808080;
    box-shadow: -1px -1px 0px 1px #fff, 1px 1px 0px 1px #000000;
    z-index: 1;
}

.taskbar-container .start-content .start-content-items {
    display: flex;
    flex-direction: column;
}

.taskbar-container .start-content .start-content-items .single-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
}

.taskbar-container .start-content .start-windows-label {
    background-color: #808080;
    border-top: 4px solid #808080;
    border-left: 4px solid #808080;
    border-right: 4px solid #808080;
    border-bottom: 4px solid #808080;
    display: flex;
    flex-direction: column-reverse;
}

.taskbar-container .taskbar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #C0C0C0;
    border-top: 2px solid #fff;
    box-shadow: 0px -2px 0px 0px #DFDFDF;
    height: 37px;
}

.taskbar-container .taskbar .taskbar-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.taskbar-container .taskbar .taskbar-items .start-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 20px;
    padding: 2px 4px 2px 4px;
    margin: 3px 5px 3px 5px;
    border-top: 2px solid #DFDFDF;
    border-left: 2px solid #DFDFDF;
    border-right: 2px solid #808080;
    border-bottom: 2px solid #808080;
    box-shadow: -1px -1px 0px 1px #fff, 1px 1px 0px 1px #000000;
}

.taskbar-container .taskbar .taskbar-items .active {
    box-shadow: -1px -1px 0px 1px #000000, 1px 1px 0px 1px #fff;
}

.taskbar-container .taskbar .taskbar-items .start-and-windows {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.taskbar-container .taskbar .taskbar-items .start-and-windows .window-button {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    background-color: #DFDFDF;
    padding: 3px 10px 3px 10px;
    box-shadow: -1px -1px 0px 1px #000000, 1px 1px 0px 1px #fff;
}

.taskbar-container .taskbar .taskbar-items .start-and-windows .windows {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.taskbar-container .taskbar .taskbar-items .start-button .start-label {
    font-weight: bolder;
    font-size: large;
}

.taskbar-container .taskbar .taskbar-items .clock-and-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    height: 20px;
    padding: 2px 10px 2px 10px;
    margin: 3px 5px 3px 5px;
    box-shadow: -1px -1px 0px 1px #808080, 1px 1px 0px 1px #DFDFDF;
}

.taskbar-container .taskbar .taskbar-items .clock-and-icons .icon-in-taskbar {
    cursor: pointer;
}