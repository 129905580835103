.icons {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 20px;
  gap: 15px;
}

.icons .single-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
}

.icons .single-icon .icon-label {
  font-weight: 600;
  font-size: large;
  text-align: center;
}
